import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';

const CheckInButton = (props) => {
    const {schedaId, stato, eventoId} = props;
    const [isCheckedIn, setIsCheckedIn] = useState(stato === 'presente');

    const handleCheckIn = () => {

        axios.post('', {scheda_id:schedaId})
            .then(response => {
                console.log(response)

                if (response.data.success) {
                    setIsCheckedIn(true);
                    toastr.success(response.data.message);
                } else {
                    setIsCheckedIn(false);
                    toastr.error(response.data.message);
                }
            })
            .catch(response => {
                setIsCheckedIn(false);
                toastr.error(response.data.message);
            })
    }

    return (
        <div>
            {!isCheckedIn && <button className="btn btn-primary btn-sm" onClick={handleCheckIn}>Segna presente</button>}
        </div>
    );
};

export default CheckInButton;


const elements = document.querySelectorAll('#check-in-button');
elements.forEach(element => {
    const props = Object.assign({}, element.dataset);
    ReactDOM.render(<CheckInButton {...props} />, element);
});

